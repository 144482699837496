.main-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 5%;
}

.aboutMe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-left: 7%;
  padding-right: 7%;
}

.text {
  padding: 2%;
  font-size: 3vh;
}

.photo {
  padding: 2%;
}

.me_and_sammy {
  max-width: 100%;
  max-height: 1000px;
  box-shadow: 10px 10px 8px #0f0f0f;
  border-radius: 5px;
}

@media (max-width: 750px) {
  .aboutMe {
    width: 100%;
    font-size: 18px;
  }
}