.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url(../Images/eu2.jpg);
  background-size: 100%;
  background-color: #0b1d2a;
  max-width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-right: 5%;
  padding-left: 5%;
}

.language {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2%;
  color: white;
  font-size: medium;
  font-weight: bold;
  width: 80%;
  cursor: pointer;
}

.title-paragraph {
  width: 80%;
  text-align: right;
  align-self: flex-end;
  color: white;
}

.contacts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: large;
  font-weight: bold;
  width: 40%;
  cursor: pointer;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.figure {
  margin-right: 2px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:link {
  text-decoration: none;
  color: rgb(255, 255, 255);
}


a:visited { 
  text-decoration: none; 
  color: rgb(255, 255, 255);
}


a:hover { 
  text-decoration: none;
  color: #74ffd5
}


a:active { 
  text-decoration: none; 
  color: #74ffd5
}

h1 {
  font-size: 80px;
  background: -webkit-linear-gradient(left, #687efa, #00ffb3);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  font-size: 40px;
  -webkit-text-stroke: .5px rgb(10, 32, 61);
}

#britishFlag {
  max-height: 14px ;
}

#brazilianFlag {
  max-height: 15px;  
}

.flags {
  height: 20px;
  padding: 5%;
}

#brazilianFlag {
    margin-right: 2px;
}

#britishFlag {
    margin-right: 2px;
}

@media (max-width: 900px) {
  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: url(../Images/eu2.jpg);
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
  }
  .title-paragraph {
    width: 100%;
  }
  .title-paragraph h1 {
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 48px;
  }
  .title-paragraph h2 {
    padding-bottom: 5%;
    font-size: 24px;
  }
  .contacts {
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
  }
}

@media (max-width: 650px) {
  .title-paragraph h1 {
    font-size: 36px;
  }
  .title-paragraph h2 {
    font-size: 18px;
    -webkit-text-stroke: .5px black;
  }
  .contacts {
    padding: 5%;
  }
}

@media (max-width: 450px) {
  .title-paragraph h1 {
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 32px;
  }
  .title-paragraph h2 {
    padding-bottom: 5%;
    font-size: 16px;
  }
  .contacts {  
    font-size: 16px;
  }
  .language {   
    font-size: 12px;    
  } 
}