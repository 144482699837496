.projects {
  background-color: rgb(10, 32, 61);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3.2vh;
  padding: 5%;
}

.projectsDetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  font-size: 2.5vh;
}

.projectsDetails p {
  padding: 2%;
}

.projectCard {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 30%;
  padding-top: 2%;
}

#projectName {
  text-align: center;
}

.folder {
  width: 100%;
}

.list {
  width: 100%;
}

@media (max-width: 680px) {
  .projectsDetails {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projectCard {  
    width: 95%;
  }
}